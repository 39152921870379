<template>
  <div class="container">
    <div class="conditions__title title">Условия доставки</div>
    <div class="conditions__text text">
      В настоящее время доставка товаров осуществляется день в день и на
      следующий день после оформления заказа.
    </div>

    <div class="conditions-price">
      <div class="conditions-price__title title title--second">
        Стоимость доставки
      </div>

      <template v-for="(city, index) in deliveryPrice" :key="index">
        <div class="conditions-price__area">
          <div class="conditions-price__icon">
            <svg class="icon icon-location ">
              <use xlink:href="#location"></use>
            </svg>
          </div>
          <div class="conditions-price__text">{{ city.main }}</div>
        </div>
        <div class="conditions-price__typography typography">
          <ul>
            <li v-for="(area, index) in city.areas" :key="index">{{ area }}</li>
          </ul>
        </div>
      </template>

      <div class="conditions-intervals">
        <div class="typography">
          <h2>Интервалы доставки</h2>
          <ul>
            <li>10:00-12:00</li>
            <li>12:00-14:00</li>
            <li>14:00-16:00</li>
            <li>16:00-18:00</li>
            <li>18:00-20:00</li>
            <li>20:00-22:00</li>
          </ul>
        </div>
      </div>

      <div class="conditions-price__message">
        <div class="conditions-price__message-title">Внимание:</div>
        <div class="conditions-price__message-text text">
          Доставка в населенные пункты по направлению г. Корсаков осуществляется
          каждый вторник с 14:00 до 16:00
        </div>
        Доставка в населенные пункты по направлению г. Долинск осуществляется
        каждую среду с 14:00 до 16:00
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    deliveryPrice() {
      return this.$store.state.deliveryPrice;
    },
  },
};
</script>
